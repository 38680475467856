import formErros from "./modules/formErros";

formErros()

import axios from 'axios'
import axiosConfig from './modules/axiosConfig'

import contatoForm from "./modules/contato-conectese";

contatoForm()


// import PageLogin from "./pages/Login";
// import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
// import DesignSystem from "./DesignSystem/DesignSystem";
// import { register } from 'swiper/element/bundle';
// import logs from "./utils/logs";
// import ripple from "./modules/ripple";
// import animaAoScroll from "./animation/animaAoScroll";
// import animateSetup from "./animation/animateSetup";

// import SvgUse from "./utils/SvgUse";

// // Init
// GLOBAL.DesignSystem = DesignSystem({})
// register() // Swiper Web Component

// // Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
// UseMouseOrKeyboard()
// ripple()
// animateSetup()
// animaAoScroll()
// // Pages
// PageLogin()?.init()

// // utils logs
// logs()

